import React, { Component } from 'react';
import BottomNav from '../containers/NewBottomNav3';
import TopNav from '../containers/NewTopNavNFTsSingle';
import TopNavMain from '../containers/NewTopNav3';
import TopNav3 from '../containers/NewTopNav3NFTMobile';
import { Container } from 'reactstrap';
import { Row, Col,Modal,ModalBody,ModalHeader } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Scrollbars } from 'react-custom-scrollbars-2';
import imgSampleFace from '../assets/img/nfts/img-51-usa-full.png'
import allNFTs from '../data/nfts'
import allNFTCountries from '../data/nftCountries'
import nftOverview from '../data/nftOverview';
import NFTsCheckoutModal from './NFTsCheckoutModal';
import { formatID, nftFlag, numberWithCommas, capitalizeWords } from '../helpers/helpers';
import nftCountries from '../data/nftCountries';
import nftOverview2 from '../data/nftOverview2';

import { Service } from '../providers/Services';
import CheckoutForm from './CheckoutForm';

import LoadingBG from './loader/LoadingBG'

// CSS
import '../assets/css/NFTsSingle.css'

// React Slider
import Slider from "react-slick";

// Modals
import LoginModal from './modal/LoginModal';
import { User } from '../providers/User';
import {customConsole, STRIPE_PROMISE_PUBLISABLE_KEY} from '../constants/defaultValues'
import { toast } from 'react-toastify';
import CheckoutCryptoConfirmationModal from './modal/CheckoutCryptoConfirmationModal';
const stripePromise = loadStripe(STRIPE_PROMISE_PUBLISABLE_KEY);
class NFTsSingle2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      data: null,
      country: null,
      loading: true,
      loginModal: false,
      nftId:"",
      isCheckout:false,
      clientSecret:"",
      // time: 1 * 60, // 15 minutes in seconds
      time: 15 * 60, // 15 minutes in seconds
      isActive: false,
      width: window.innerWidth,
      height: window.innerHeight,
      paymentLoader: false,
      hasToken: false,
      showCryptoModalConfirmation: false
    };
  }
  setModal = (e, status) => {

    // Check if valid user token
    User.getUser().then(userData => {
        this.setState({...this.state, modal: status});
        // customConsole.log('success get user', userData)
    }).catch(error => {

        toast.error('Please login again!');

        localStorage.clear();

        setTimeout(function() {
            window.location.href = "/en/nfts";
        }, 3000); // 3000 milliseconds = 3 seconds

        // customConsole.log('error get user', error)
    });

    e.preventDefault();
  }

  setCheckoutCryptoModal = (e, status) => {
    e.preventDefault();
    this.setState({...this.state, showCryptoModalConfirmation: status});
  }

  getData = () => {
    const paramID = this.props.match.params.id
    const id = Math.abs(paramID)
    const data = allNFTs.filter(nft => nft.id == id)
    if (data) {
        return data[0]
    } else {
        return null
    }
  }
  getCountryFlag(country) {
    // Format spaces with hyphen and to lowercase
    const formatCountry =  country.replace(/\s+/g, '-').toLowerCase();
    return formatCountry
  }
  getCountryDetails(countryName) {
    const data = allNFTCountries.find(country => country.name.toLowerCase() === countryName.toLowerCase());

    if (data) {
        return data
    } else {
        return {}
    }
  }

  startTimer = () => {
    if (!this.state.isActive) {
      this.setState({ isActive: true });
      this.timer = setInterval(() => {
        this.setState(prevState => {
          if (prevState.time > 0) {
            return { time: prevState.time - 1 };
          } else {
            clearInterval(this.timer);

            toast.error('Time Is Over, Please Checkout Again')

            setTimeout(function() {
                window.location.reload();

                return { isActive: false };
            }, 3000); // 3000 milliseconds = 3 seconds
          }
        });
      }, 1000);
    }
  };

  formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  getPaymentIntent = async (e) => {
    this.setState({ paymentLoader: true })
    Service.createPaymentIntent({nft: this.state.nftId, payment_mode:0})
    .then(res => {
        // Start Timer
        this.startTimer();
        this.setState({clientSecret:res.clientSecret,isCheckout:true,modal:false})
        customConsole.log(res)
        this.setState({ paymentLoader: false })
    })
    .catch(err => {
        toast.error('NFT Already Sold or Is In Progress, Please Try Again Later!');
        this.setState({ paymentLoader: false })
    })
  }

  getCryptoPaymentIntent = () => {
    this.setState({ paymentLoader: true })
    Service.createPaymentIntent({nft: this.state.nftId, payment_mode:1})
    .then(res => {
        // Start Timer
        // this.startTimer();
        // this.setState({clientSecret:res.clientSecret,isCheckout:true,modal:false})
        console.log('ccssP:::::: ',res.chargeUrl)
        window.location.href = res.chargeUrl;
    })
    .catch(err => {
        toast.error('NFT Already Sold or Is In Progress, Please Try Again Later!');
        this.setState({ paymentLoader: false })
        this.setState({ showCryptoModalConfirmation: false })
    })
  }

  handleCloseCheckout = () => {
    this.setState({isCheckout:false})
  }

  handlePaymentResult = () => {
    const { history, match, location } = this.props;
    const urlParams = new URLSearchParams(location.search);

    const redirectStatus = urlParams.get('redirect_status');
    const nftId = match.params.id
    
    if (redirectStatus === 'succeeded') {
      // Payment was successful
      // Redirect to your desired URL without query parameters
      history.replace(`/en/nfts/${nftId}`);
    }
  }
  componentDidMount() {
    // Check If Valid User 
    // User.hasToken().then(isValid => {
    //     this.setState({ hasToken: isValid });
    // });

    User.getUser().then(data => {
        this.setState({ hasToken: true });
    }).catch(error => {
        this.setState({ hasToken: false });
    })

    window.addEventListener('resize', this.handleResize);
    this.handlePaymentResult();
    const paramID = this.props.match.params.id
    this.setState({ nftId: paramID })
    Service.getNFT(paramID)
    .then(res => {
        customConsole.log('Single Fetch:', res)
        // customConsole.log('NFTS#####', res)
        // const paramID = this.props.match.params.id
        // const filteredData = res.find(nft => nft.id === paramID)
        // this.setState({ data: filteredData })
        // this.setState({ loading: false })

        // IF Sold redirect to NFTS page
        if (res.status == 1) {
            window.location = `/en/nfts/`;
            return;
        }

        this.setState({ data: res })
    })
    .catch(err => {
        customConsole.error('Error fetching data:', err);
    })
  }

  handleModalClose = () => {
    this.setState({ loginModal: false })
}

    handleLoginModal = (e, status) => {
        e.preventDefault()
        this.setState({loginModal: status});
    }

    onCheckoutInModal = (e)=>{
        e.preventDefault()
        this.getPaymentIntent()
    }

    onCheckoutInModalCrypto = (e)=>{
        e.preventDefault()
        this.getCryptoPaymentIntent()
    }

    toggleCryptoModalConfirmation = (e, status) => {
        e.preventDefault(); 
        this.setState({ showCryptoModalConfirmation: status, modal: !status })
    }

    componentWillUnmount() {
        clearInterval(this.timer); // Cleanup the interval on unmount
        window.removeEventListener('resize', this.handleResize);
      }

      handleResize = () => {
        this.setState({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };

  render() {
    const { t } = this.props;

    const { data } = this.state;

    const currentUrl = window.location.href;

    const { width, height } = this.state;

    // const country = this.getCountryDetails(data.country)

    const settings = {
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: 5,
        slidesToScroll: 5,
        arrows: true,
        responsive: [
            {
                breakpoint: 911,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 601,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '60px'
                }
            },
        ]
    };

    const settings2 = {
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: 5,
        slidesToScroll: 5,
        arrows: true,
        responsive: [
            {
                breakpoint: 911,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 601,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '60px'
                }
            },
        ]
    };

    if (data === null) {
        return <div>Loading...</div>; // Render a loading state while fetching data
    }

    // return (
    //     <div>
    //       <h1>Data from API:</h1>
    //       <pre>{JSON.stringify(data, null, 2)}</pre>
    //     </div>
    //   );

    function walletLevel2(type) {
        return (type === 1) ? '10%' : (type === 2) ? '20%' : (type === 3) ? '30%' : '';
    }

    function membershipLevel2(type) {
        return (type === 1) ? '15%' : (type === 2) ? '20%' : (type === 3) ? '25%' : '';
    }

    function merchantsLevel2(type) {
        return (type === 1) ? '20' : (type === 2) ? '25' : (type === 3) ? '30' : '';
    }
    const {isCheckout,clientSecret} = this.state
    const appearance = {
        theme: 'stripe',
      };
      const options = {
        clientSecret,
        appearance,
      };
    return (
       <>
       <LoadingBG show={this.state.paymentLoader} />

            {/* Payment Form */}
            <Modal 
                isOpen={this.state.isCheckout} 
            >
            <ModalBody>
                <div class="nft-checkout-modal-header">
                    <h2 class="nft-checkout-modal-header-title">Checkout</h2>
                    <p class="nft-checkout-modal-header-close">
                        <a href="#" onClick={this.handleCloseCheckout}><span class="material-symbols-outlined">close</span></a>
                    </p>
                </div>
                <div className="App">
                    <Elements options={options} stripe={stripePromise} >
                        <CheckoutForm nftId={this.state.nftId} />
                    </Elements>
                </div>

                <div className='red-text nft-checkout-timer'>
                    Checkout Before: {this.formatTime(this.state.time)}
                </div>
            </ModalBody>
        </Modal>
       <div className='nfts-main nfts-main-single v3-nfts-single'>

            <div className='v3-top-nav-main-top'>
                <TopNavMain />
            </div>

            {/* HEADER */}

            {/* HEADER */}
            <TopNav />
           
            
            {/* SECTION 2 */}
            <section className='nfts-curve nfts-2'>
                <Container>
                    <Row className='mobile'>
                        <Col>
                            <div class='header-nfts-single-middle header-nfts-single-middle-mobile'>
                                <a className='nft-single-back-btn' href='/nfts'><i class="fas fa-chevron-left"></i> Back to list</a>
                                <p className='poppins n-font-27 bold dark-text-5 mb-0'>Series "A" Sale</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='align-center'>
                        <Col>
                            <div className='nfts-single-box'>
                                {/* HEADER */}
                                <div className='nfts-single-box-header'>
                                    <div className='nfts-single-box-header-col nfts-single-box-header-col-1'>
                                        <p className='nfts-single-box-header-id'>#{ data.nft_number }</p>
                                    </div>
                                    <div className='nfts-single-box-header-col nfts-single-box-header-col-2'>
                                        <h1 className='nfts-single-box-title'>{'expression'}</h1>
                                    </div>
                                    <div className='nfts-single-box-header-col nfts-single-box-right nfts-single-box-header-col-3'>
                                        {/* <p className='nfts-single-box-right-title'>Territory</p>
                                        <div className='nfts-single-box-right-img'>
                                            <img src={require(`../assets/img/flags/${this.getCountryFlag(data.country)}-flag.svg`)} alt={data.country} />
                                        </div>
                                        <p className='nfts-single-box-right-country'>{ data.country }</p> */}
                                    </div>
                                </div>
                                <div className='nfts-single-box-body'>
                                    <div className='nfts-single-box-body-col nfts-single-box-body-left'>
                                        <h2 className='nfts-single-box-body-left-title'>Overview</h2>
                                        <p className='nfts-single-box-body-left-content'>{ nftOverview2(data) }</p>
                                        <div className='nfts-single-box-body-left-img'>
                                            <img src={require(`../assets/img/nft-images/${data.nft_name}.png`).default } />
                                        </div>
                                    </div>
                                    <div className='nfts-single-box-body-col nfts-single-box-body-right'>
                                        <h2 className='nfts-single-box-body-right-price-title'>Price</h2>
                                        <p className='nfts-single-box-body-right-price'>${data.price ? data.price : '--'} USD</p>
                                        <div className='nfts-single-btn-wrap'>
                                        { this.state.hasToken 
                                            ?
                                            <>
                                                <a href='#' className='nfts-single-btn' onClick={(e) => this.setModal(e, true)}>Buy Now</a>
                                            </>
                                        : 
                                            <a className='nfts-single-btn' href='#' onClick={(e) => this.handleLoginModal(e, true)}>Buy Now</a>
                                        }  
                                        </div>
                                        <div className='nfts-single-box-body-group nfts-single-box-body-group-1'>
                                            <h2 className='nfts-single-box-body-group-title'>Assets</h2>
                                            <p className='nfts-single-box-body-group-content'>{ data.ovo_tokens ? numberWithCommas(data.ovo_tokens) + ' OVO' : '--' }</p>
                                        </div>
                                        <div className='nfts-single-box-body-group'>
                                            <h2 className='nfts-single-box-body-group-title'>Income Producing</h2>
                                            <p className='nfts-single-box-body-group-content'>Yes</p>
                                        </div>
                                        <div className='nfts-single-box-body-group'>
                                            <h2 className='nfts-single-box-body-group-title'>Capacity</h2>
                                            <p className='nfts-single-box-body-group-content'>{ data.cap ? numberWithCommas(data.cap) : '--' } OVO</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='nfts-single-box-before-footer'>
                                    <h2 className='nfts-single-box-before-footer-col nfts-single-box-before-footer-title'>Included</h2>
                                    <p className='nfts-single-box-before-footer-col nfts-single-box-before-footer-content'>NFT #{ formatID(data.id) } falls in country {data.country} 
                                    {country.people ? `, a country with approximately ${country.people} people ` : ' '}
                                    {country.gnp ? ` and a GNP of over ${ country.gnp }, ` : ' '}
                                    
                                    
                                    
                                    has a {capitalizeWords(data.mask_type)} Mask with {capitalizeWords(data.expression)} expression. It has a total capacity of {numberWithCommas(data.capacity)} with OVO Assets amounting to {numberWithCommas(data.assets)}. You can earn {data.retail_royalties}  Royalty share with this NFT and get {data.dao_participation} votes in DAO Participation, NFT wallet, and 3D Printing.</p>
                                </div> */}
                                <div className='nfts-single-box-footer'>
                                    <h2 className='nfts-single-box-footer-title'>Income Producing Properties</h2>

                                    {/* Level 1 */}
                                    <div className='v2-mt-2 nft-single-level-wrap'>
                                        <div className='nft-single-level-text'>Level 1</div>
                                    </div>

                                    <Slider {...settings} className='nfts-single-box-footer-list-wrap nfts-single-box-footer-list-wrap-2'>
                                        <div className='nfts-single-box-footer-list-col'>
                                            <div className='nfts-single-box-footer-list-col-inner'>
                                                <h3 className='nfts-single-box-footer-list-col-title'>Wallets</h3>
                                                <p className='nfts-single-box-footer-list-col-text-2'>$2-$20</p>
                                            </div>
                                        </div>
                                        <div className='nfts-single-box-footer-list-col'>
                                            <div className='nfts-single-box-footer-list-col-inner'>
                                                <h3 className='nfts-single-box-footer-list-col-title'>Memberships</h3>
                                                <p className='nfts-single-box-footer-list-col-text-2'>25%</p>
                                            </div>
                                        </div>
                                        <div className='nfts-single-box-footer-list-col'>
                                            <div className='nfts-single-box-footer-list-col-inner'>
                                                <h3 className='nfts-single-box-footer-list-col-title'>NFTs</h3>
                                                <p className='nfts-single-box-footer-list-col-text-2'>{data.nftL1 ? data.nftL1 + '%' : '--'}</p>
                                            </div> 
                                        </div>
                                        <div className='nfts-single-box-footer-list-col'>
                                            <div className='nfts-single-box-footer-list-col-inner'>
                                                <h3 className='nfts-single-box-footer-list-col-title'>Merchants</h3>
                                                <p className='nfts-single-box-footer-list-col-text-2'>$10-$100</p>
                                            </div>
                                        </div>
                                        <div className='nfts-single-box-footer-list-col'>
                                            <div className='nfts-single-box-footer-list-col-inner'>
                                                <h3 className='nfts-single-box-footer-list-col-title'>Retail Royalties</h3>
                                                <p className='nfts-single-box-footer-list-col-text-2'>{data.retailL1 ? numberWithCommas(data.retailL1) + '%'  : '--'} </p>
                                            </div>
                                        </div>
                                    </Slider>
                                    
                                    { (data.type !== 1) && (
                                        <>
                                        {/* Level 2 */}
                                        <div className='v2-mt-2 nft-single-level-wrap'>
                                            <div className='nft-single-level-text'>Level 2</div>
                                        </div>

                                        {/* Level 2 Details */}
                                        <Slider {...settings2} className='nfts-single-box-footer-list-wrap nfts-single-box-footer-list-wrap-2'>
                                            <div className='nfts-single-box-footer-list-col'>
                                                <div className='nfts-single-box-footer-list-col-inner'>
                                                    <h3 className='nfts-single-box-footer-list-col-title'>Wallets</h3>
                                                    <p className='nfts-single-box-footer-list-col-text-2'>{ walletLevel2(data.type) }</p>
                                                </div>
                                            </div>
                                            <div className='nfts-single-box-footer-list-col'>
                                                <div className='nfts-single-box-footer-list-col-inner'>
                                                    <h3 className='nfts-single-box-footer-list-col-title'>Memberships</h3>
                                                    <p className='nfts-single-box-footer-list-col-text-2'>{ membershipLevel2(data.type) }</p>
                                                </div>
                                            </div>
                                            <div className='nfts-single-box-footer-list-col'>
                                                <div className='nfts-single-box-footer-list-col-inner'>
                                                    <h3 className='nfts-single-box-footer-list-col-title'>NFTs</h3>
                                                    <p className='nfts-single-box-footer-list-col-text-2'>{data.nftL2 ? data.nftL2 + '%' : '--'}</p>
                                                </div> 
                                            </div>
                                            <div className='nfts-single-box-footer-list-col'>
                                                <div className='nfts-single-box-footer-list-col-inner'>
                                                    <h3 className='nfts-single-box-footer-list-col-title'>Merchants</h3>
                                                    <p className='nfts-single-box-footer-list-col-text-2'>
                                                        ${ merchantsLevel2(data.type) }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='nfts-single-box-footer-list-col'>
                                                <div className='nfts-single-box-footer-list-col-inner'>
                                                    <h3 className='nfts-single-box-footer-list-col-title'>Retail Royalties</h3>
                                                    <p className='nfts-single-box-footer-list-col-text-2'>{data.retailL2 ? numberWithCommas(data.retailL2) + '%'  : '--'} </p>
                                                </div>
                                            </div>
                                        </Slider>
                                        </>
                                    ) }
                                    
                                    <div className='nfts-single-btn-wrap nfts-single-box-footer-btn-wrap'>
                                    { this.state.hasToken 
                                        ?
                                        <a className='nfts-single-btn' href='#' onClick={(e) => this.setModal(e, true)}>Buy Now</a>
                                     : 
                                     <a className='nfts-single-btn' href='#' onClick={(e) => this.handleLoginModal(e, true)}>Buy Now</a>
                                     }  
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            
            {/* FOOTER */}
            <BottomNav />
        </div>
        
        {/* NFT Checkout Modal */}
        <NFTsCheckoutModal 
            show={this.state.modal} 
            setModal={this.setModal} 
            item={data} 
            onCheckout={this.onCheckoutInModal} 
            onCheckoutCryptoConfirmation={this.toggleCryptoModalConfirmation}
            timer={this.formatTime(this.state.time)} 
        />

        {/* Login Modal */}
        <LoginModal showModal={this.state.loginModal} handleModalClose={this.handleModalClose} redirectURL={currentUrl} />

        {/* Checkout Crypto Modal */}
        <CheckoutCryptoConfirmationModal
            item={data} 
            show={this.state.showCryptoModalConfirmation} 
            setModal={this.setCheckoutCryptoModal}
            onCheckout={this.onCheckoutInModalCrypto}
         />
       </>
    );
  }
}
export default withTranslation()(NFTsSingle2);