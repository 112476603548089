const device = require('react-device-detect')
const TESTNET = true

export const WALLET_URL = TESTNET ? "https://testnet-wallet.ovato.com/":"https://wallet.ovato.com/"
export const EXPLORER_URL = TESTNET ? "https://testnet-explorer.ovato.com/" : "https://explorer.ovato.com/"
export const GA_ID = TESTNET ? "UA-206598033-1" : "UA-110626080-1"
export const baseApiUrl = TESTNET ? "https://testnet-api-wallet.ovato.com/":"https://api-wallet.ovato.com/"
export const baseUserAuthApiUrl = TESTNET ? "https://auth-api-testtnet.ovato.com/" : "https://api-auth.ovato.com/"

export const deviceDetect = {
    os : device.osName,
    browsername : device.browserName,
    devicetype : device.deviceType,
    plateform : 'website'
}

export const explorerBaseApiUrl  = TESTNET ? "https://testnet-api-explorer.ovato.com/" : "https://api-explorer.ovato.com/"

export const secret = TESTNET ? "2dca16c51ed42a4af36240dc1941fb" : "85f8a2f8e2ac250e37c6d8"


// const baseApiUrl = "http://0.0.0.0:9002/"
export default baseApiUrl
export const toastStyle = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined
}
export const wallet = "dev"
export const paginationdata = 20
export const maintenancepage=false

// export const WALLET_URL = "http://0.0.0.0:9002/"
export const OVATO_URL = TESTNET ? "https://testnet.ovato.com/" : "https://ovato.com/"
export const STRIPE_PROMISE_PUBLISABLE_KEY = TESTNET ? "pk_test_51PN5FUIyXXACTIES4DbZIul9uLjvD8Eb3VmPh8iwMbWi1RcSZOdNqnLWii2sPzVEYEwPWPgzAQ4iFfZXVN9kKeDT00cIsaOt2r" : "pk_test_51PN5FUIyXXACTIES4DbZIul9uLjvD8Eb3VmPh8iwMbWi1RcSZOdNqnLWii2sPzVEYEwPWPgzAQ4iFfZXVN9kKeDT00cIsaOt2r"


export const customConsole = {
    log: (...args) => {
      if (TESTNET === 'local') {
        console.log(...args);
      }
    },
    debug: (...args) => {
      if (TESTNET === 'local') {
        console.debug(...args);
      }
    }
    // Add more methods as needed
};
