import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import SampleImg1 from '../assets/img/img-checkout-sample-img-1.png'
import SampleImg2 from '../assets/img/img-checkout-sample-img-2.png'
import ImgCoinbase from '../assets/img/img-coinbase.jpg'
import ImgLedger from '../assets/img/img-ledger.png'
import ImgMetamask from '../assets/img/img-metamask.png'
import ImgWalletConnect from '../assets/img/img-wallet-connect.png'
import ImgVisa from '../assets/img/img-checkout-visa.png'
import ImgAmericanExpress from '../assets/img/img-checkout-american-express.png'
import ImgMasterCard from '../assets/img/img-checkout-mastercard.png'
import ImgPaypal from '../assets/img/img-checkout-paypal.png'
import NFTsModalStyle from '../assets/css/NFTsCheckoutModal.css'
import { Service } from '../providers/Services';
import CheckoutForm from './CheckoutForm'
import CheckoutTimer from './checkout/CheckoutTimer';
import { toast } from 'react-toastify';


class NFTsCheckoutModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nftId:"",
    };
  }

  componentDidMount() {
    const {item} = this.props
    this.setState({ nftId: item.id })
  }

  render() {
    const { t, setModal, item, timer } = this.props;
 
    
    return (
        
        <div className={`nft-checkout-modal-container ${this.props.show ? 'active' : ''}`}>
            <div className='nft-checkout-modal-backdrop'></div>
            <div className='nft-checkout-modal-row'>
                <form className='nft-checkout-form'>
                    {/* HEADER */}
                    <div className='nft-checkout-modal-header'>
                        <h2 className='nft-checkout-modal-header-title'>Checkout</h2>
                        <p className='nft-checkout-modal-header-close'><a href='#' onClick={(e) => setModal(e, false)}><span class="material-symbols-outlined">close</span></a></p>
                    </div>
                    {/* ITEMS */}
                    <div className='nft-checkout-modal-items-row'>
                        <h3 className='nft-checkout-modal-items-title'>Item</h3>
                        { item && (
                            <ul className='nft-checkout-modal-items'>
                                <li>
                                    <div className='nft-checkout-modal-items-img'>
                                        {/* <img src={SampleImg1} alt='Sample Image 1' /> */}
                                        <img src={require(`../assets/img/nft-images/${item.nft_name}.png`).default } />
                                    </div>
                                    
                                    <span class="nft-checkout-modal-items-checked material-symbols-outlined">
                                        check_circle
                                    </span>
                                    <div className='nft-checkout-modal-items-desc'>
                                        <p className='nft-checkout-modal-items-desc-title'>NFT #:{ item.nft_number }</p>
                                        <p className='nft-checkout-modal-items-desc-price'>Price ${item.price}</p>
                                    </div>
                                </li>
                                {/* <li>
                                    <div className='nft-checkout-modal-items-img'>
                                        <img src={SampleImg2} alt='Sample Image 2' />
                                    </div>
                                    
                                    <span class="nft-checkout-modal-items-checked material-symbols-outlined">
                                        check_circle
                                    </span>
                                    <div className='nft-checkout-modal-items-desc'>
                                        <p className='nft-checkout-modal-items-desc-title'>Product Name Here</p>
                                        <p className='nft-checkout-modal-items-desc-price'>Price $3000</p>
                                    </div>
                                </li> */}
                            </ul>
                        ) }
                    </div>
                    {/* WALLETS */}
                    <div className='nft-checkout-payment-row'>
                        <h3 className='nft-checkout-wallets-heading'>Pay in Crypto</h3>
                        <ul className='nft-checkout-payment-lists'>
                            <li>
                                {/* <a href='#'> */}
                                    <img src={ImgCoinbase} style={{width:'115px'}} alt='Coinbase' />
                                {/* </a> */}
                            </li>
                            <li>
                                {/* <a href='#'> */}
                                    <img src={ImgLedger} style={{width:'115px'}} alt='Ledger' />
                                {/* </a> */}
                            </li>
                            <li>
                                {/* <a href='#'> */}
                                    <img src={ImgMetamask} style={{width:'115px'}} alt='Metamask' />
                                {/* </a> */}
                            </li>
                            <li>
                                {/* <a href='#'> */}
                                    <img src={ImgWalletConnect} style={{width:'115px'}} alt='Wallet Connect' />
                                {/* </a> */}
                            </li>
                        </ul>
                    </div>
                    <div className='nft-checkout-total'>
                        <p className='nft-checkout-total-text'>Total: <strong>{item.price} USD</strong></p>
                    </div>
                    <div className='nft-checkout-button-row'>
                        <button className='nft-checkout-button' onClick={(e) => this.props.onCheckoutCryptoConfirmation(e, true)}>Pay In Crypto</button>
                    </div>
                    {/* PAYMENTS */}
                    <div className='nft-checkout-payment-row'>
                        <h3 className='nft-checkout-wallets-heading'>Pay in Cash</h3>
                        <ul className='nft-checkout-payment-lists'>
                            <li>
                                <img src={ImgVisa} alt="Visa" />
                            </li>
                            <li>
                                <img src={ImgMasterCard} alt="Master Card" />
                            </li>
                            <li>
                                <img src={ImgAmericanExpress} alt="American Express" />
                            </li>
                            <li>
                                <img src={ImgPaypal} alt="Paypal" />
                            </li>
                        </ul>
                    </div>
                    {/* TOTAL */}
                    <div className='nft-checkout-total'>
                        <p className='nft-checkout-total-text'>Total: <strong>{item.price} USD</strong></p>
                    </div>
                    
                    {/* SUBMIT BUTTON */}
                    <div className='nft-checkout-button-row'>
                        <button className='nft-checkout-button' onClick={this.props.onCheckout}>Pay Now</button>
                    </div>
                </form>
                
            </div>
        </div>
    );
  }
}
export default withTranslation()(NFTsCheckoutModal);